var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card card-custom zoom ribbon ribbon-clip ribbon-right m-5"},[_c('div',{staticClass:"card-body py-5 ribbon ribbon-top ribbon-ver shadow-sm"},[(_vm.publishAssessmentsMaster && _vm.ideaSimpleAssessmentStatusId > 1)?_c('div',{staticClass:"ribbon-target p-3",staticStyle:{"top":"12px"}},[_c('span',{staticClass:"ribbon-inner",style:('color:#fff; background-color:' + _vm.ideaSimpleAssessmentStatusColor)}),_c('span',{staticClass:"text-uppercase font-weight-bolder"},[_vm._v(_vm._s(_vm.assessment))])]):_vm._e(),_c('div',{staticClass:"d-flex flex-wrap",staticStyle:{"gap":"15px"}},[_c('div',{style:(`background: url('${
          _vm.idea.logo ? _vm.idea.logo.uri : '/assets/media/bipart/idea-default.svg'
        }') no-repeat center; background-size: cover; width:100px; height:100px`)}),_c('div',{staticClass:"d-flex flex-column",staticStyle:{"gap":"15px"}},[_c('h6',{staticClass:"font-weight-bold mb-0"},[_vm._v(" "+_vm._s(_vm._f("truncate")(_vm.idea.title,50, "..."))+" ")]),_c('div',{staticClass:"mt-auto"},[(_vm.idea.activeCommenting)?_c('span',{staticClass:"p-2 mr-1 mt-2"},[_c('i',{staticClass:"fas fa-comment text-primary"}),_vm._v(" "+_vm._s(_vm.idea.countIdeaComments))]):_vm._e(),(_vm.idea.activeFollowing)?_c('span',{staticClass:"mr-3 mt-2"},[_c('i',{staticClass:"fas fa-heart text-danger"}),_vm._v(" "+_vm._s(_vm.idea.countFollowers))]):_vm._e()])]),_c('div',{staticClass:"d-flex ml-auto align-items-end"},[(_vm.idea.id)?_c('router-link',{attrs:{"to":{
            name: 'processModuleIdeaDetail',
            params: {
              id: _vm.idea.id,
              process_id: _vm.idea.processId,
              phase_id: _vm.idea.processPhaseId,
              module_id: _vm.idea.process2ModuleId,
              phase_status_id: _vm.idea.processPhaseId,
            },
          }}},[_c('span',{staticClass:"mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase"},[_vm._v(_vm._s(_vm.$t("PARTIALSPROCESSI.VISUALIZZA")))])]):_vm._e()],1)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }