<template>
  <div class="row h-100 justify-content-center">
    <div class="col-lg-10 col-xl-10 mt-5">
      <div class="row mb-5" style="gap: 15px">
        <div class="overlay">
          <div class="overlay-wrapper">
            <img
              :src="icon_uri"
              alt=""
              class="rounded"
              style="height: 150px; width: auto"
            />
          </div>
          <div v-if="challenge.url" class="overlay-layer">
            <a
              :href="challenge.url"
              target="_blank"
              class="btn btn-lg font-weight-bold btn-primary btn-shadow"
              >{{ $t("DRIVERS.LINK") }}</a
            >
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span class="font-size-h1 text-dark font-weight-bolder">{{
            challenge.name
          }}</span>
        </div>
      </div>


      <div class="row">
        <div v-if="challenge.description" class="col-12 p-0 mb-3">
          <span class="h3 font-size-bolder text-primary">{{
            $t("DRIVERS.DESCRIZIONE")
          }}</span>
        </div>

        <div class="col-12 p-0">
          <div v-if="challenge.description" class="card mb-3 card-shadow">
            <div class="card-body">
              <div
                class="h5 m-0 p-0 challenge-detail-description"
                v-html="challenge.description"
              ></div>
            </div>
          </div>
        </div>

        <div v-if="hasProcesses" class="col-12 p-0 mt-5">
          <span class="h3 font-size-bolder text-primary">{{
            $t("DRIVERS.PROCESSES")
          }}</span>
        </div>

        <div v-if="hasProcesses" class="col-12 p-0">
          <b-tabs class="w-100">
            <b-tab v-if="hasActiveProcesses" active>
              <template v-slot:title>
                <div class="p-3 border-bottom border-2 border-bottom-primary">
                  {{ $t("DRIVERS.ACTIVEPROCESSES") }}
                </div>
              </template>
              <div v-for="process in activeProcesses" :key="process.id">
                <div
                  v-if="process.ideas.length > 0"
                  class="card card-shadow mb-3"
                >
                  <div class="card-body p-0">
                    <div>
                      <div
                        class="alert alert-custom alert-2x bg-primary py-2 alert-text text-uppercase font-weight-bolder align-items-center"
                        style="
                          border-bottom-right-radius: 0;
                          border-bottom-left-radius: 0;
                        "
                      >
                        <h5 class="aling-items-center mt-2 text-white">
                          {{ process.title }}
                        </h5>
                      </div>
                      <ChallengeDetailIdeaCard
                        v-for="idea in process.ideas"
                        :key="idea.id"
                        :idea="idea"
                      ></ChallengeDetailIdeaCard>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
            <b-tab v-if="hasCloseProcesses">
              <template v-slot:title>
                <div class="p-3 border-bottom border-2 border-bottom-primary">
                  {{ $t("DRIVERS.CLOSEPROCESSES") }}
                </div>
              </template>
              <div v-for="process in closeProcesses" :key="process.id">
                <div
                  v-if="process.ideas.length > 0"
                  class="card card-shadow mb-3"
                >
                  <div class="card-body p-0">
                    <div>
                      <div
                        class="alert alert-custom alert-2x bg-primary py-2 alert-text text-uppercase font-weight-bolder align-items-center"
                        style="
                          border-bottom-right-radius: 0;
                          border-bottom-left-radius: 0;
                        "
                      >
                        <h5 class="aling-items-center mt-2 text-white">
                          {{ process.title }}
                        </h5>
                      </div>
                      <ChallengeDetailIdeaCard
                        v-for="idea in process.ideas"
                        :key="idea.id"
                        :idea="idea"
                      ></ChallengeDetailIdeaCard>
                    </div>
                  </div>
                </div>
              </div>
            </b-tab>
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import ChallengeDetailIdeaCard from "./partials/ChallengeDetailIdeaCard.vue";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  name: "challenge-detail",
  data() {
    return {
      challenge: [],
      id: this.$route.params.id,
      icon_uri: null,
      activeProcesses: [],
      closeProcesses: [],
      hasProcesses: false,
      hasActiveProcesses: false,
      hasCloseProcesses: false,
    };
  },
  components: {
    ChallengeDetailIdeaCard,
  },
  computed: {
    ...mapGetters(["getOrganization"]),
  },
  mounted() {
    this.getData();
  },
  created() {},
  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        this.$isLoading(true);
        var endpoint = this.generateUrl("challenges-expanded/" + this.id, true);
        //generateUrl is done
        ApiService.get(this.$apiResource, endpoint)
          .then((res) => {
            this.challenge = res?.data;
            this.icon_uri =
              res?.data?.icon?.uri || this.$imageOptions.defaultLogoUrl;

            const today = parseInt(moment(new Date()).format("YYYYMMDD"));

            const processes = res?.data?.processes || [];
            this.activeProcesses = processes.filter((p) => {
              const startDate = parseInt(
                moment(p.startDate).format("YYYYMMDD")
              );
              const endDate = parseInt(moment(p.endDate).format("YYYYMMDD"));

              if (
                (typeof startDate === "number" && today < startDate) ||
                (typeof endDate === "number" && today > endDate)
              ) {
                return false;
              }

              return true;
            });
            this.closeProcesses = processes.filter((p) => {
              const endDate = parseInt(moment(p.endDate).format("YYYYMMDD"));
              return typeof endDate === "number" && today > endDate;
            });

            this.hasActiveProcesses = this.activeProcesses.some(
              (x) => x.ideas.length > 0
            );

            this.hasCloseProcesses = this.closeProcesses.some(
              (x) => x.ideas.length > 0
            );

            this.hasProcesses =
              this.hasActiveProcesses || this.hasCloseProcesses;

            let qs = `?organizationId.equals=${this.getOrganization.data.id}`;
            endpoint = this.generateUrl(
              `agendas/${this.challenge.agendaId}`,
              true
            );
            ApiService.get(this.$apiResource, endpoint + qs)
              .then((resDriver) => {
                const name = resDriver?.data?.name;
                // const countChallenges = resDriver.data.countChallenges;
                const countAgendas =
                  this.getOrganization?.data?.countAgendas || 0;
                this.$store.dispatch(SET_BREADCRUMB, [
                  {
                    title: this.$t("DRIVERS.ELENCOCHALLENGE"),
                    route: countAgendas > 1 ? "/driver-list" : "disabled",
                  },
                  {
                    title: name,
                    // route:
                    //   countChallenges > 1
                    //     ? `/driver-detail/${this.challenge.agendaId}/${name}`
                    //     : "disabled",
                    route: `/driver-detail/${this.challenge.agendaId}/${name}`,
                  },
                  { title: this.challenge?.name || "" },
                ]);
              })
              .catch(() => {
                reject(0);
                this.$isLoading(false);
              });

            this.$isLoading(false);
            resolve(1);
          })
          .catch(() => {
            this.$isLoading(false);
            reject(0);
          });
      });
    },
  },
};
</script>

<style>
.challenge-detail-description p {
  margin-bottom: 0 !important;
}
</style>
