<template>
  <div class="card card-custom zoom ribbon ribbon-clip ribbon-right m-5">
    <div class="card-body py-5 ribbon ribbon-top ribbon-ver shadow-sm">
      <!--Ribbon Assessment-->
      <div
        class="ribbon-target p-3"
        style="top: 12px"
        v-if="publishAssessmentsMaster && ideaSimpleAssessmentStatusId > 1"
      >
        <span
          class="ribbon-inner"
          :style="
            'color:#fff; background-color:' + ideaSimpleAssessmentStatusColor
          "
        ></span>
        <span class="text-uppercase font-weight-bolder">{{ assessment }}</span>
      </div>
      <!--end::Ribbon Assessment-->

      <div class="d-flex flex-wrap" style="gap: 15px">
        <div
          :style="`background: url('${
            idea.logo ? idea.logo.uri : '/assets/media/bipart/idea-default.svg'
          }') no-repeat center; background-size: cover; width:100px; height:100px`"
        ></div>

        <div class="d-flex flex-column" style="gap: 15px">
          <h6 class="font-weight-bold mb-0">
            {{ idea.title | truncate(50, "...") }}
          </h6>
          <div class="mt-auto">
            <span v-if="idea.activeCommenting" class="p-2 mr-1 mt-2"
              ><i class="fas fa-comment text-primary"></i>
              {{ idea.countIdeaComments }}</span
            >
            <span v-if="idea.activeFollowing" class="mr-3 mt-2"
              ><i class="fas fa-heart text-danger"></i>
              {{ idea.countFollowers }}</span
            >
          </div>
        </div>

        <div class="d-flex ml-auto align-items-end">
          <router-link
            v-if="idea.id"
            :to="{
              name: 'processModuleIdeaDetail',
              params: {
                id: idea.id,
                process_id: idea.processId,
                phase_id: idea.processPhaseId,
                module_id: idea.process2ModuleId,
                phase_status_id: idea.processPhaseId,
              },
            }"
          >
            <span
              class="mt-1 btn bg-warning text-white btn-block font-weight-bolder text-uppercase"
              >{{ $t("PARTIALSPROCESSI.VISUALIZZA") }}</span
            >
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ChallengeDetailIdeaCard",
  props: ["idea"],
  data() {
    return {
      assessment: "",
      ideaSimpleAssessmentStatusId: 1,
      ideaSimpleAssessmentStatusColor: "",
      publishAssessmentsMaster: false,
    };
  },
  mounted() {
    if (this.idea) {
      this.publishAssessmentsMaster = this.idea.publishAssessmentsMaster;
      this.ideaSimpleAssessmentStatusColor =
        this.idea?.ideaSimpleAssessmentStatus?.color ?? "";
      this.ideaSimpleAssessmentStatusId =
        this.idea?.ideaSimpleAssessmentStatus?.id ?? 1;
      this.assessment =
        this.idea?.ideaSimpleAssessmentStatus?.localizations[0]?.name ?? "";
    }
  },
  filters: {
    truncate(text, length, clamp) {
      clamp = clamp || "...";
      let node = document.createElement("div");
      node.innerHTML = text;
      let content = node.textContent;
      return content.length > length
        ? content.slice(0, length) + clamp
        : content;
    },
  },
};
</script>

<style scoped></style>
